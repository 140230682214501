
.home-deck{
    background-color: #171717;
    width: calc(25% - 12px);
    border-radius: 4px;
    transition: transform .5s;
    transform-style: preserve-3d;
    position: relative;
    margin-left: 5px;
    margin-right: 7px;
    margin-bottom: 10px;
}

@media (max-width: 767.98px) {
    .home-deck{
        width:calc(50% - 12px);
    }

    .post-3, .post-4, .post-5, .post-6{
        display: none;
    }

    .deck_name, .deck_author{
        font-size: 0.8rem;
    }

    .article-title{
        font-size: 1rem !important;
    }
}

.SubTitle{
    color: #ffd780;
    text-align: center;
    margin-bottom: 0px;
    padding-top: 5px;
    font-family: Varela Round;
}

.SubTitleMini{
    color: #ffd780;
    margin-top: -7px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 16px;
    font-family: Varela Round
}


.home-deck:hover{
    transform: scale(1.03)
}

.home-deck_bio{
    position: relative;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.card_1{
    left: 0;
    z-index: 5;
}

.card_2{
    left: 25%;
    z-index: 4;
}

.card_3{
    left: 45%;
    z-index: 3;
}

.card_4{
    right: 0;
    z-index: 2;
}

.card_5{
    right: 0;
    visibility: hidden;
}

.deck_name{
    color: white;
    font-weight: bold;
    margin: 0;
}

.deck_author{
    color: #00c4e1;
    font-weight: bolder;
    margin-bottom: 0px;
}

.article-box{
    margin-left: -5px;
    margin-right: -5px;

}

#articleCol {
    padding-bottom: 0px !important;
}

body{
    font-family: Varela Round;
}