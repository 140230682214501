@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap');

#content{
    background-color: #222431;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.24), 0 1px 3px rgba(0,0,0,.12);
    padding: 5px;
}

#about-content{
    margin: 0px 0px 3px 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Varela Round;
    overflow:hidden;
}

.AboutPage{
    padding-top: 0px;
}

h2{
    font-size: 20px !important;
}

h3{
    font-size: 18px !important;
}

#blogImage{
    padding-bottom: 15px;
}