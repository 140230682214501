.react-bootstrap-table{
    box-shadow: 0 1px 2px rgb(0 0 0 / 24%), 0 1px 3px rgb(0 0 0 / 12%);
}
.page-link{
    color: black !important;
}

.page-item.active{
    color: white !important;
    background-color: #007bff !important;
}

.page-link:hover {
    color: #007bff !important;
}