.article-title{
    position: absolute;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 0px;
    bottom:0px;
    padding: 5px 8px;
    background: rgba(6, 13, 21, 0.65);
    color: #ffd780
}

.article-box{
    transition: transform .5s;
    border-radius: 4px;
    transform-style: preserve-3d;
    border: 1px solid #313131;
}

.article-box:hover{
    transform: scale(1.05)
}

#article-image{
    border-radius: 4px;
    width:100%;
}

#articleCol{
    padding-bottom: 10px;
    padding-top: 10px;
}

#content{
    background-color: #222431;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.24), 0 1px 3px rgba(0,0,0,.12);
    padding: 5px;
}