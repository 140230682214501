.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  color: #ffd780 !important;
}

html {
  scroll-behavior: auto;
}

body{
  background-color: #1d1f29 !important;
}

h2{
  font-weight:500;
  color:white;
}

h5{
  color:#a7b1c1;
}

h6{
  color:#a7b1c1;
}

h4, h3{
  color:white;
}

p{
  color:#a7b1c1;
}

#navtop{
  margin-bottom: 10px;
  border-bottom: 1px solid #36384a;
  background-color: #222431;
}

#navbottom{
  margin-top: 10px;
  border-top: 1px solid #36384a;
  background-color: #222431;
}

.css-1idqsaz{
  display: block !important;
  margin: 0 auto;
}

body{
  line-height: 1.3;
}

#ncmp-consent-link{
  position:fixed;
  bottom: 0%;
}

#bottomLeaderboard{
  padding-top: 5px;
  padding-bottom: 5px;
}

#topLeaderboard{
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(39, 41, 55);
  height: 100px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px){

  #bottomLeaderboard{
    background-color: rgb(39, 41, 55);
    /*height:260px;*/
  }

  #topLeaderboard{
    background-color: rgb(39, 41, 55);
    height:62px;
    /*margin-bottom: 10px;*/
  }

}