@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover{
  color:rgb(255, 255, 255) !important;
}
#content{
    background-color: #222431;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.24), 0 1px 3px rgba(0,0,0,.12);
    padding: 5px;
}

#about-content{
    margin: 0px 0px 3px 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Varela Round;
    overflow:hidden;
}

.AboutPage{
    padding-top: 0px;
}

h2{
    font-size: 20px !important;
}

h3{
    font-size: 18px !important;
}

#blogImage{
    padding-bottom: 15px;
}
.react-bootstrap-table{
    box-shadow: 0 1px 2px rgb(0 0 0 / 24%), 0 1px 3px rgb(0 0 0 / 12%);
}
.page-link{
    color: black !important;
}

.page-item.active{
    color: white !important;
    background-color: #007bff !important;
}

.page-link:hover {
    color: #007bff !important;
}
#navtop .nav-link{
    font-weight: 500;
    padding-right: 20px;
}

@media only screen and (max-width: 991px){
#navtop{
    margin-bottom: 5px;
}
}

.home-deck{
    background-color: #171717;
    width: calc(25% - 12px);
    border-radius: 4px;
    transition: transform .5s;
    transform-style: preserve-3d;
    position: relative;
    margin-left: 5px;
    margin-right: 7px;
    margin-bottom: 10px;
}

@media (max-width: 767.98px) {
    .home-deck{
        width:calc(50% - 12px);
    }

    .post-3, .post-4, .post-5, .post-6{
        display: none;
    }

    .deck_name, .deck_author{
        font-size: 0.8rem;
    }

    .article-title{
        font-size: 1rem !important;
    }
}

.SubTitle{
    color: #ffd780;
    text-align: center;
    margin-bottom: 0px;
    padding-top: 5px;
    font-family: Varela Round;
}

.SubTitleMini{
    color: #ffd780;
    margin-top: -7px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 16px;
    font-family: Varela Round
}


.home-deck:hover{
    transform: scale(1.03)
}

.home-deck_bio{
    position: relative;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.card_1{
    left: 0;
    z-index: 5;
}

.card_2{
    left: 25%;
    z-index: 4;
}

.card_3{
    left: 45%;
    z-index: 3;
}

.card_4{
    right: 0;
    z-index: 2;
}

.card_5{
    right: 0;
    visibility: hidden;
}

.deck_name{
    color: white;
    font-weight: bold;
    margin: 0;
}

.deck_author{
    color: #00c4e1;
    font-weight: bolder;
    margin-bottom: 0px;
}

.article-box{
    margin-left: -5px;
    margin-right: -5px;

}

#articleCol {
    padding-bottom: 0px !important;
}

body{
    font-family: Varela Round;
}
.article-title{
    position: absolute;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 0px;
    bottom:0px;
    padding: 5px 8px;
    background: rgba(6, 13, 21, 0.65);
    color: #ffd780
}

.article-box{
    transition: transform .5s;
    border-radius: 4px;
    transform-style: preserve-3d;
    border: 1px solid #313131;
}

.article-box:hover{
    transform: scale(1.05)
}

#article-image{
    border-radius: 4px;
    width:100%;
}

#articleCol{
    padding-bottom: 10px;
    padding-top: 10px;
}

#content{
    background-color: #222431;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.24), 0 1px 3px rgba(0,0,0,.12);
    padding: 5px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  color: #ffd780 !important;
}

html {
  scroll-behavior: auto;
}

body{
  background-color: #1d1f29 !important;
}

h2{
  font-weight:500;
  color:white;
}

h5{
  color:#a7b1c1;
}

h6{
  color:#a7b1c1;
}

h4, h3{
  color:white;
}

p{
  color:#a7b1c1;
}

#navtop{
  margin-bottom: 10px;
  border-bottom: 1px solid #36384a;
  background-color: #222431;
}

#navbottom{
  margin-top: 10px;
  border-top: 1px solid #36384a;
  background-color: #222431;
}

.css-1idqsaz{
  display: block !important;
  margin: 0 auto;
}

body{
  line-height: 1.3;
}

#ncmp-consent-link{
  position:fixed;
  bottom: 0%;
}

#bottomLeaderboard{
  padding-top: 5px;
  padding-bottom: 5px;
}

#topLeaderboard{
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(39, 41, 55);
  height: 100px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px){

  #bottomLeaderboard{
    background-color: rgb(39, 41, 55);
    /*height:260px;*/
  }

  #topLeaderboard{
    background-color: rgb(39, 41, 55);
    height:62px;
    /*margin-bottom: 10px;*/
  }

}
